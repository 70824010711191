import React from "react";
import BaseCodeVerification from "../BaseCodeVerification";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "300px",

        "& label.Mui-focused": {
            color: theme.palette.primary.main,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.grey.dark,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            },
        },
    },
}));

const CodeVerification = (props) => {
    const styles = useStyles();
    return (
        <BaseCodeVerification
            classes={styles}
            {...props}
        />
    );
};

export default CodeVerification;
