import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "../hooks/use-media-query";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useLabelStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
}));
const useFormHelperStyles = makeStyles((theme) => ({
    root: {
        fontSize: "12px",
        textAlign: "center",
        fontWeight: "500",
        position: "absolute",
        top: "100%",
    },
}));
const useInputStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.h5.fontFamily,
        fontWeight: theme.typography.h5.fontWeight,
    },
}));

const BaseInput = (props) => {
    const {
        // common
        name,
        error,
        styles,
        inputCacheValue,
        updateField,
        // component props
        type,
        classes,
        className,
        inputRef,
        label,
        fullWidth,
        onKeyUp,
        multiline,
        tooltip,
        isDisabled,
        hasAutoFocus,
    } = props;

    const textInputRef = useRef(null);
    const [shrinkState, setShrinkState] = useState(false);
    const labelStyles = useLabelStyles();
    const labelProps = {
        classes: labelStyles,
        shrink: shrinkState,
    };
    const formHelperStyles = useFormHelperStyles();
    const formHelperTextProps = {
        classes: formHelperStyles,
    };
    const inputStyles = useInputStyles();
    const inputContainerProps = {
        "data-cy": `${name}_container`,
        classes: inputStyles,
        endAdornment: (tooltip)
            ? (
                <InputAdornment position="end">
                    <Tooltip
                        arrow
                        interactive
                        placement="top"
                        TransitionComponent={Zoom}
                        title={<h3 style={{ textAlign: "center" }}>{tooltip.content}</h3>}
                    >
                        {tooltip.component}
                    </Tooltip>
                </InputAdornment>
            )
            : <></>,
    };
    const inputProps = {
        "data-cy": name,
        ref: textInputRef,
    };

    const { customLayout } = useSiteMetadata();
    const isDesktopBreakpoint = useMediaQuery((theme) => theme.breakpoints.up(1024), { noSsr: true });

    // set autofocus via refs & set shrink state to true if input has content
    useEffect(() => {
        if (!customLayout && (hasAutoFocus && isDesktopBreakpoint)){
            setShrinkState(true);
            textInputRef.current.focus();
        }
        if (textInputRef.current.value){
            setShrinkState(true);
        }
    }, [customLayout, hasAutoFocus, isDesktopBreakpoint, textInputRef]);

    const helperText = error
        ? props.helperText || error
        : null;

    const onChange = (e) => {
        const value = e.target.value.trim();
        updateField(value);
    };

    return (
        <TextField
            id={name}
            classes={classes}
            className={clsx(className)}
            style={styles}
            error={!!error}
            inputRef={inputRef}
            onChange={props.onChange || onChange}
            defaultValue={inputCacheValue || undefined}
            helperText={props.helperText || helperText}
            label={label}
            name={name}
            multiline={multiline}
            disabled={isDisabled || false}
            color="secondary"
            FormHelperTextProps={formHelperTextProps}
            InputLabelProps={labelProps}
            InputProps={inputContainerProps}
            inputProps={inputProps}
            type={type}
            variant="outlined"
            fullWidth={fullWidth}
            onKeyUp={onKeyUp}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            }}
            onBlur={(e) => {
                if (!textInputRef.current.value) setShrinkState(false);
            }}
            onFocus={(e) => {
                setShrinkState(true);
                if (isDesktopBreakpoint){ e.currentTarget.select(); }
            }}
        />
    );
};

BaseInput.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    name: PropTypes.string,
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object,
};

BaseInput.defaultProps = {
    inputRef: null,
    name: "",
    label: "",
    errors: null,
};

export default BaseInput;