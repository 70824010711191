import React, { useState, useEffect } from "react";
import TextInput from "@components/TextInput";
import Select from "@components/Select";
import Grid from "./Grid";
import { provinceBlacklist, findProvince } from "./Address";
import {
    postalLabel,
    regionLabel,
    regionCodes,
} from "@locales";

const manualInputsMeta = [
    {
        key: "address",
        label: "Address",
        type: "string",
        helperText: "Address is required",
    },
    {
        key: "postalCode",
        label: postalLabel,
        type: "string",
        helperText: `${postalLabel} is required`,
    },
    {
        key: "city",
        label: "City",
        type: "string",
        helperText: "City is required",
    },
    {
        key: "provinceCode",
        label: regionLabel,
        type: "select",
        options: regionCodes,
        helperText: `${regionLabel} is required`,
    },
];

const ManualAddress = (props) => {
    const {
        getField,
        updateFormField,
        updateField,
        error,
        saveToAppState,
        actions,
        useGrid,
    } = props;

    const [addressState, setAddressState] = useState(() => (
        manualInputsMeta.reduce((acc, { key }) => (
            Object.assign(acc, {
                [key]: getField(key),
            })
        ), {})
    ));
    const [selectValue, setSelectValue] = useState(addressState.provinceCode);
    const [hasProvinceErr, setHasProvinceErr] = useState(false);
    const [provinceErrMsg, setProvinceErrMsg] = useState(null);

    useEffect(() => {
        updateFormField(addressState, false);
    }, [addressState, updateFormField]);

    useEffect(() => {
        if (hasProvinceErr) {
            setHasProvinceErr(false);
            setProvinceErrMsg(null);
        }
        if (provinceBlacklist.includes(selectValue)) {
            updateFormField({}, false);
            setHasProvinceErr(true);
            const provinceObj = findProvince(selectValue);
            const msgContent = provinceObj ? `${provinceObj.label} Residents.` : "your area.";
            setProvinceErrMsg(`We are currently unable to service ${msgContent}`);
        }
    }, [hasProvinceErr, selectValue, updateFormField]);

    const handleUpdate = (key, value) => {
        const newState = {
            ...addressState,
            [key]: value,
        };
        setAddressState(newState);
        key === "provinceCode" && setSelectValue(value);
        if (hasProvinceErr && !error) {
            delete newState.provinceCode;
        }
        updateFormField(newState);
        updateField(value, key);
    };

    const defaultInputStyles = { width: "100%" };

    return (
        <Grid
            useGrid={useGrid}
            boxProps={defaultInputStyles}
        >
            {
                manualInputsMeta.map((input, i) => {
                    const helperText = error
                        ? input.helperText
                        : "";

                    const onChange = (e) => handleUpdate(input.key, e.target.value);

                    return (input.type === "string"
                        ? (
                            <TextInput
                                index={i}
                                key={i}
                                name={input.key}
                                styles={defaultInputStyles}
                                error={error}
                                type={input.type}
                                label={input.label}
                                fullWidth
                                onKeyUp={actions.onEnterKeyPressed}
                                hasAutoFocus={i === 0}
                                helperText={helperText}
                                onChange={onChange}
                                inputCacheValue={getField(input.key)}
                                updateField={updateField}
                            />
                        ) : (
                            <Select
                                index={i}
                                key={i}
                                name={input.key}
                                styles={defaultInputStyles}
                                error={hasProvinceErr || error}
                                options={input.options}
                                label={input.label}
                                value={selectValue}
                                helperText={hasProvinceErr ? provinceErrMsg : helperText}
                                onChange={onChange}
                                updateFormField={updateFormField}
                                saveToAppState={saveToAppState}
                            />
                        )
                    );
                })
            }
        </Grid>
    );
};

export default ManualAddress;