import React from "react";
import BaseInput from "../BaseInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "300px",
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&input[type=number]": {
            "-moz-appearance": "textfield",
        },
    },
}));

const TextInput = (props) => {
    const styles = useStyles();
    return (
        <BaseInput
            classes={styles}
            {...props}
        />
    );
};

export default TextInput;
