export const isArraysEqual = (requiredArr, foundArr) => {
    requiredArr.sort();
    foundArr.sort();
    if (requiredArr instanceof Array && foundArr instanceof Array) {
        if (requiredArr.length !== foundArr.length) return false;
        for (let i = 0; i < requiredArr.length; i++) {
            if (requiredArr[i] !== foundArr[i]) return false;
        }
        return true;
    }
};