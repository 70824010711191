import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner = (props) => {
    const { boxProps, spinnerProps } = props;
    return (
        <Box {...boxProps}>
            <CircularProgress {...spinnerProps} />
        </Box>
    );
};

export default LoadingSpinner;