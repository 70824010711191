import React, { useState, useRef, createRef } from "react";
import dayjs from "dayjs";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "../hooks/make-styles-wrapper";

const useDobStyles = makeStyles(
    (theme, overrides, merge) => ({
        root: merge(
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            overrides,
        ),
    }),
    "dob.container",
);

/*
    valid date formats: [
        "DD-MM-YYYY", ~default
        "MM-DD-YYYY",
        "YYYY-MM-DD"
    ]
*/
const DateInput = (props) => {
    const {
        name,
        styles,
        error,
        inputCacheValue,
        updateFormField,
        updateField,
        format = "DD-MM-YYYY",
        hasAutoFocus,
        goToNextStep,
    } = props;

    const NUM_OF_INPUTS = 3;
    const dateLabels = format.toUpperCase().split("-");
    const yearInputIndex = (dateLabels[2] === "YYYY") ? 2 : 0;

    const autoFocusIdx = useRef(0);
    const [inputValues, setInputValues] = useState(() => {
        let dateValues = new Array(NUM_OF_INPUTS).fill("");
        if (inputCacheValue) {
            const dateObj = dayjs(inputCacheValue);
            updateFormField(dateObj, false);
            dateValues = dateObj
                .format(format)
                .split(/[-/\\. ]+/);
        }
        return {
            values: dateValues,
            autoFocusIdx,
        };
    });
    const [inputRefs, setInputRefs] = useState(() => (
        Array(NUM_OF_INPUTS).fill().map((_, i) => createRef())
    ));

    const containerStyles = useDobStyles();

    const onFocus = (e) => {
        e.target.select(e);
    };

    const onChange = async (e) => {
        let { values } = inputValues;
        const currInputIdx = parseInt(e.target.dataset.id);
        let val = e.target.value;
        let nextInputIdx = null;
        let nextRef = null;

        val = val.replace(/[^\d]/gi, "");
        if (val === "") return;
        values = Object.assign([], values);

        if (currInputIdx === yearInputIndex) { /* year input */
            if (val.length > 4) return;
            values[currInputIdx] = val;
            setInputValues({ values });
            if (val.length === 4) nextInputIdx = currInputIdx + 1;
        } else { /* day + month input */
            if (val.length > 2) return;
            values[currInputIdx] = val;
            setInputValues({ values });
            nextInputIdx = values[currInputIdx].length + (currInputIdx - 1);
        }
        if (nextInputIdx < NUM_OF_INPUTS) nextRef = inputRefs[nextInputIdx];
        if (nextRef) nextRef.current.focus();

        /* validation and saving to form + context */
        const strValue = values.join("-");
        const date = dayjs(strValue, format, true);
        if (values.join("").length === 8) {
            updateFormField(date);
            if (date.isValid()) {
                const valueToSave = date.toISOString();
                updateField(valueToSave);
            }
        } else {
            updateFormField(date, false);
        }
    };

    const doKeyDownAction = (e, indices) => {
        const { keyCode } = e;
        const { currInputIdx, prevIdx, prevRef, nextRef } = indices;
        switch(keyCode) {
            case 8: /* backspace */
                e.preventDefault();
                const values = [...inputValues.values];
                if (inputValues.values[currInputIdx]) {
                    values[currInputIdx] = "";
                    setInputValues({ values });
                } else if (prevRef) {
                    values[prevIdx] = "";
                    prevRef.current.focus();
                    setInputValues({ values });
                }
                const strValue = values.join("-");
                const date = dayjs(strValue, format, true);
                updateFormField(date, false);
                break;
            case 37: /* left */
                e.preventDefault();
                if (prevRef) prevRef.current.focus();
                break;
            case 39: /* right */
                e.preventDefault();
                if (nextRef) nextRef.current.focus();
                break;
            case 38: /* up */
                e.preventDefault();
                break;
            case 40: /* down */
                e.preventDefault();
                break;
            case 13: /* return/enter key */
                e.preventDefault();
                goToNextStep();
                break;
            default:
                break;
        }
    };

    const onKeyDown = (e) => {
        const currInputIdx = parseInt(e.target.dataset.id);
        const prevIdx = currInputIdx - 1;
        const nextIdx = currInputIdx + 1;
        const prevRef = inputRefs[prevIdx];
        const nextRef = inputRefs[nextIdx];
        const indices = { currInputIdx, prevIdx, nextIdx, prevRef, nextRef };

        doKeyDownAction(e, indices);
    };

    const helperTextStyles = {
        visibility: error ? "visible" : "hidden",
        marginTop: "5px",
        color: "red",
        padding: "0px 15px",
        fontSize: "13px",
        fontWeight: 500,
        fontFamily: "'Montserrat', sans-serif",
    };

    return (
        <Box className={containerStyles.root}>
            <Box>
                {
                    inputValues.values.map((val, i) => {
                        const nativeInputProps = {
                            "data-id": i,
                            "data-cy": `${name}-input-${i}`,
                        };
                        return (
                            <TextField
                                key={`date-input-${i}`}
                                variant="outlined"
                                type="tel"
                                color="secondary"
                                style={{
                                    width: (i === yearInputIndex) ? "110px" : "75px",
                                    margin: "0px 4px",
                                }}
                                inputRef={inputRefs[i]}
                                placeholder={dateLabels[i]}
                                inputProps={nativeInputProps}
                                label={dateLabels[i]}
                                value={val}
                                autoFocus={hasAutoFocus && i === autoFocusIdx.current}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onFocus={onFocus}
                                error={!!error}
                            />
                        );
                    })
                }
            </Box>
            <Box
                style={helperTextStyles}
            >
                {error}
            </Box>
        </Box>
    );
};

export default DateInput;