import React from "react";
import BaseSelect from "../BaseSelect";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "300px",
    },
}));

const Select = (props) => {
    const styles = useStyles();
    return (
        <BaseSelect
            classes={styles}
            {...props}
        />
    );
};

export default Select;
