import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { config } from "config";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Box from "@material-ui/core/Box";
import { navigate } from "gatsby";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { normalize } from "../utils/normalize";
import { makeStyles } from "../hooks/make-styles-wrapper";
import { getTransitionDelayTime } from "../utils/get-transition-delay-time";
import ContinueButton from "@components/ContinueButton";
import LoadingSpinner from "../components/LoadingSpinner";
import Header from "../components/Header";
import { getRenderList } from "./inputs";
import { InputsRenderer } from "./InputsRenderer";

const useStyles = makeStyles(
    (theme, overrides, merge) => ({
        container: merge(
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",

                [theme.breakpoints.up(320)]: {
                    // marginBottom: "-8px",
                    marginBottom: "25px",
                    marginTop: "-16px",
                },
                [theme.breakpoints.up(360)]: {
                    marginBottom: "24px",
                    marginTop: "-16px",
                },
                [theme.breakpoints.up(400)]: {
                    marginBottom: "24px",
                    marginTop: "-8px",
                },
                [theme.breakpoints.up(600)]: {
                    marginBottom: "44px",
                    marginTop: "0px",
                },
            },
            overrides,
        ),
        headerBoxStyles: {
            width: "331px",
            height: "110px",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            marginTop: "24px",
            marginBottom: "30px",

            [theme.breakpoints.down(350)]: {
                width: "auto",
            },
        },
    }),
    "inputsRenderer.container",
);

const navigateToNext = (context, nextStep) => {
    try {
        const nextRoute = nextStep();
        if (!context.stepsData.includes(nextRoute)) {
            context.addStepToStepsData(nextRoute);
        }
        navigate(normalize(nextRoute));
    } catch (err) {
        console.error(err);
    }
};

const ConfigRenderer = (props) => {
    const {
        fixedHeight,
    } = config;

    const {
        context,
        stepName,
        step,
        nextStep,
        inputs,
        onSubmit = () => Promise.resolve(),
    } = props;

    const {
        // classic
        title,
        description,
        // chat
        chat,
        waitTime,
        continueButtonText,
    } = step;

    const preInputChatMessagesSize = chat && chat.preInput.messages.length;
    const transitionDelayForInput = chat
        ? getTransitionDelayTime(preInputChatMessagesSize)
        : "0ms";
    const waitTimeDelay = (waitTime === undefined || waitTime === null)
        ? 800 // default delay of 0.8s
        : waitTime*1000;

    const styles = useStyles();
    const heightStyles = useMemo(() => ({
        style: {
            height: "max-content",
            minHeight: "1px",
        },
    }), []);

    // Initialize Schema
    const [schema] = useState(() => {
        let shape = {};
        inputs.forEach((input) => {
            if (input.group) {
                input.inputs?.forEach((groupInput) => {
                    shape[groupInput.key] = groupInput.validations;
                });
            } else {
                shape[input.key] = input.validations;
            }
        });
        return yupResolver(yup.object().shape(shape));
    });

    // Initialize Form
    // {
    //     register,
    //     handleSubmit,
    //     setError,
    //     clearError,
    //     errors,
    //     formState,
    //     trigger,
    //     setValue,
    //     getValues,
    // }
    const formActions = useForm({ mode: "onBlur", resolver: schema });
    const triggerValidation = formActions.trigger;

    const [{ activeIndex, renderList, hideSubmit }, setRenderList] = useState(() => (
        getRenderList(step, inputs, 0)
    ));
    const [curInputIndex, setInputIndex] = useState(activeIndex);
    const firstRender = useRef(true);
    const transitionTimer = useRef(null);

    useEffect(() => {
        if (firstRender.current === false) {
            setRenderList(getRenderList(step, inputs, curInputIndex));
        }
        firstRender.current = false;
    }, [curInputIndex, firstRender, step, inputs]);

    const [doStepTransition, startStepTransition] = useState(false);

    const goToNextStep = useCallback(() => {
        startStepTransition(true);
    }, []);

    const onEnterKeyPressed = useCallback((e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            goToNextStep();
            return false;
        }
    }, [goToNextStep]);

    const onComplete = useCallback(async (curIndex) => {
        if (transitionTimer.current !== null) {
            clearTimeout(transitionTimer.current);
        }

        const isValid = await triggerValidation();
        if (!isValid) {
            return console.warn("input(s) failed form validation...");
        }
        const nextIndex = activeIndex + 1;
        if (curIndex >= activeIndex && activeIndex > -1) {
            setInputIndex(nextIndex);
        }
        if (
            (activeIndex === -1 || nextIndex >= inputs.length) &&
            hideSubmit
        ) {
            transitionTimer.current = setTimeout(() => {
                goToNextStep();
                transitionTimer.current = null;
            }, waitTimeDelay);
        }
    }, [activeIndex, goToNextStep, hideSubmit, inputs, triggerValidation, waitTimeDelay]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const transitioning = useRef(false);
    useEffect(() => {
        if (doStepTransition && !transitioning.current) {
            startStepTransition(false);
            setIsSubmitting(true);

            transitioning.current = true;
            formActions.trigger()
                .then(async (result) => {
                    if (result) {
                        return await onSubmit() ?? true;
                    }
                })
                .then((result) => {
                    if (result && nextStep() !== null) {
                        navigateToNext(context, nextStep);
                    }
                })
                .finally(() => {
                    transitioning.current = false;
                });
        }
    }, [doStepTransition, formActions, context, nextStep, onSubmit]);

    const inputsRendererBoxProps = useMemo(() => ({
        ...(!fixedHeight || stepName === "success") && heightStyles,
    }), [fixedHeight, heightStyles, stepName]);

    const spinnerBoxProps = {
        visibility: isSubmitting ? "visible" : "hidden",
        marginBottom: "10px",
        clone: true,
    };
    const spinnerProps = {
        color: "primary",
    };

    const headerProps = {
        title: title,
        description: description,
        boxStyles: styles.headerBoxStyles,
    };

    const actions = useMemo(() => ({
        goToNextStep,
        onEnterKeyPressed,
        onComplete,
        navigateToNext,
    }), [goToNextStep, onComplete, onEnterKeyPressed]);

    return (
        <React.Fragment>
            {title && // step `title` property
                <Grow in timeout={500}>
                    <Header {...headerProps} />
                </Grow>
            }
            <Box
                className={clsx(styles.container)}
                {...inputsRendererBoxProps}
                data-cy={`route_${stepName}_inputs`}
            >
                <InputsRenderer
                    stepName={stepName}
                    step={step}
                    stepInputs={inputs}
                    transitionDelayForInput={transitionDelayForInput}
                    context={context}
                    formActions={formActions}
                    actions={actions}
                    renderList={renderList}
                />
            </Box>
            <Fade
                in
                style={{ transitionDelay: transitionDelayForInput }}
                timeout={500}
            >
                <Box
                    display={hideSubmit ? "none" : "flex"}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                    mb={6}
                    data-cy={"continue"}
                >
                    {stepName === "submit" &&
                        <LoadingSpinner
                            boxProps={spinnerBoxProps}
                            spinnerProps={spinnerProps}
                        />
                    }
                    <ContinueButton
                        onClick={goToNextStep}
                        text={continueButtonText}
                    />
                </Box>
            </Fade>
        </React.Fragment>
    );
};

export default ConfigRenderer;