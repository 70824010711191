import Select from "@components/Select";
import DateInput from "../components/DateInput";
import Address from "../components/Address";
import SuccessCtaSection from "../components/SuccessCtaSection";
import PhoneNumber from "@components/PhoneNumber";
import TextInput from "@components/TextInput";
import ChipGroup from "@components/ChipGroup";
import CodeVerification from "@components/CodeVerification";
import {
    SearchingLenders,
    SuccessBlurb,
} from "@components/Presentational";
import SubmitBlurb from "@components/SubmitBlurb";
import { RadioButtonVariantSet } from "@components/RadioButton";
import ChatBot from "@components/ChatBot";

const textComponent = (overrideProps = () => {}) => ({
    component: TextInput,
    register: false,
    space: 5,
    getProps: (stepInput, formActions, actions) => ({
        type: stepInput.type,
        inputRef: formActions.register,
        label: stepInput.label,
        fullWidth: true,
        onKeyUp: actions.onEnterKeyPressed,
        hasAutoFocus: stepInput.index === 0,
        ...overrideProps(stepInput, formActions, actions),
    }),
});

const radioComponent = (component, overrideProps = () => {}) => ({
    component,
    register: true,
    hideSubmit: true,
    getProps: (stepInput, formActions, actions) => ({
        data: stepInput.options,
        onComplete: actions.onComplete,
        autoContinue: stepInput.autoContinue ?? true,
        ...overrideProps(stepInput, formActions, actions),
    }),
});

const presentationalComponent = (component, overrideProps = () => {}) => ({
    component,
    register: false,
    hideSubmit: true,
    getProps: (stepInput, formActions, actions) => ({
        onComplete: actions.onComplete,
        ...overrideProps(stepInput, formActions, actions),
    }),
});

export const componentIndex = {
    "number": textComponent(),
    "string": textComponent(),
    "textarea": textComponent(() => ({
        multiline: true,
    })),
    "select": {
        component: Select,
        register: true,
        getProps: ({ options, label, multiple }) => ({
            options,
            label,
            multiple,
        }),
    },
    "date": {
        component: DateInput,
        register: true,
        hideSubmit: false,
        space: 5,
        getProps: (stepInput, formActions, actions) => ({
            format: stepInput.format,
            hasAutoFocus: stepInput.index === 0,
            goToNextStep: actions.goToNextStep,
        }),
    },
    "choice": {
        component: ChipGroup,
        register: true,
        hideSubmit: true,
        getProps: (
            { key, options, autoContinue },
            { formState },
            { goToNextStep },
        ) => ({
            options,
            touched: !!formState.touched[key],
            autoContinue: autoContinue ?? true,
            goToNextStep,
        }),
    },
    "address": {
        component: Address,
        register: true,
        space: 5,
        getProps: (
            { key, label, useGrid, disableManualInputs },
            { getValues, setError },
            actions,
        ) => ({
            name: key,
            label,
            useGrid,
            disableManualInputs,
            getValues,
            setError,
            actions,
        }),
    },
    "tel": {
        component: PhoneNumber,
        register: true,
        space: 5,
        hideSubmit: false,
        getProps: (
            { index, label },
            formActions,
            { onEnterKeyPressed },
        ) => ({
            label,
            onKeyUp: onEnterKeyPressed,
            hasAutoFocus: index === 0,
        }),
    },
    "radio": radioComponent(RadioButtonVariantSet, () => ({
        variant: "normal",
    })),
    "radio-card": radioComponent(RadioButtonVariantSet, () => ({
        variant: "card",
    })),
    "radio-vertical": radioComponent(RadioButtonVariantSet, () => ({
        variant: "vertical",
    })),
    "chat-bot": {
        component: ChatBot,
        register: false,
        getProps: ({ messages, hasTypingIndicator, action = {} }, formActions, actions) => ({
            agentName: "Sophia",
            messages,
            hasTypingIndicator,
            // goToNextStep: actions.goToNextStep,
        }),
    },
    "verify-code": {
        component: CodeVerification,
        register: true,
        hideSubmit: true,
        getProps: (stepInput, formActions, actions) => ({
            inputType: "number",
            numberOfCells: 4,
            cellWidth: 65,
            cellHeight: 65,
            autoFocus: true,
            goToNextStep: actions.goToNextStep,
            onComplete: actions.onComplete,
        }),
    },
    "submit-blurb": {
        component: SubmitBlurb,
        register: false,
        getProps: (stepInput, formActions, actions) => ({
            onComplete: actions.onComplete,
            title: stepInput.title,
            buttonText: stepInput.buttonText,
            body1: stepInput.body1,
            body2: stepInput.body2,
        }),
    },
    "presentational-searching": presentationalComponent(SearchingLenders, ({ searchingForList }, formActions, actions) => ({
        searchingForList,
    })),
    "presentational-success": presentationalComponent(SuccessBlurb, ({ title, body }, formActions, actions) => ({
        title,
        body,
    })),
    "presentational-success-ctas": presentationalComponent(SuccessCtaSection, (stepInput, formActions, actions) => ({
        compact: stepInput.compact,
    })),
};

const inputHidesSubmit = (step, inputType) => {
    const inStep = "hideSubmit" in step;
    const inInputRegistry =
        inputType in componentIndex &&
        "hideSubmit" in componentIndex[inputType];

    // pull value from step level,
    // otherwise grab value from input registry
    if (inStep) return step.hideSubmit;
    if (inInputRegistry) return componentIndex[inputType].hideSubmit;
    // default value
    return false;
};

//** ChatBot and Input meta data go into render list */
export const getRenderList = (
    step,
    inputs,
    activeInput,
    options = {},
) => {
    const {
        showPreInput = step.chat,
    } = options;

    const renderList = [];
    const chatMeta = step.chat || {};
    // push preInput chatbot meta data
    if (showPreInput && "preInput" in chatMeta) {
        renderList.push({
            key: "message-preInput",
            type: "chat-bot",
            ...chatMeta.preInput,
        });
    }

    const postInput = chatMeta.postInput || {};
    let hideSubmit = false;
    // returns index of curr input in inputs that needs to wait;
    // returns -1 if none found
    const newIndex = inputs.findIndex((stepInput, i) => {
        // const isFunction = typeof stepInput === "function";
        const curKey = stepInput.key;
        const hasPostInput = curKey in postInput;
        const waitForInput = hasPostInput && i >= activeInput;
        // push step input data into render list
        renderList.push({
            ...stepInput,
            index: i,
        });
        // push postInput chatbot meta data into render list
        // if theres no need to wait for input
        if (hasPostInput && !waitForInput) {
            renderList.push({
                key: `postInput-${curKey}`,
                type: "chat-bot",
                ...postInput[curKey],
            });
        }
        // check if input component has hideSubmit set
        hideSubmit = inputHidesSubmit(step, stepInput.type);
        return waitForInput;
    });

    return {
        activeIndex: newIndex,
        hideSubmit,
        renderList: renderList.filter(Boolean),
    };
};