import React from "react";
import Box from "@material-ui/core/Box";
import MaterialChip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        height: "48px",
        borderRadius: "6px",
        background: "#e8eaee",
        fontSize: theme.typography.body1.fontSize,
        color: "#6d7278",
        fontFamily: "poppins",
        "&:focus": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
        },
        "&:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const Chip = ({ name, label, onClick, onBlur }) => {
    const styles = useStyles();
    return (
        <Box className={styles.container} width="220px" clone>
            <MaterialChip
                onClick={onClick}
                onBlur={onBlur}
                data-cy={name.toString()}
                name={name.toString()}
                label={label}
                clickable
            />
        </Box>
    );
};

export default Chip;
