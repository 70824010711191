import React, { Children, useMemo } from "react";
import Box from "@material-ui/core/Box";
import MuiGrid from "@material-ui/core/Grid";

const Grid = ({
    useGrid,
    containerProps = {},
    gridProps = {},
    boxProps = {},
    children,
}) => {
    const { ContainerWrapper, props: containerWrapperProps } = useMemo(() => (
        useGrid ? {
            ContainerWrapper: MuiGrid,
            props: {
                container: true,
                spacing: 3,
                ...containerProps,
            },
        } : {
            ContainerWrapper: React.Fragment,
            props: {},
        }
    ), [containerProps, useGrid]);

    const { InputWrapper, props: inputWrapperProps } = useMemo(() => (
        useGrid ? {
            InputWrapper: MuiGrid,
            props: {
                xs: 6,
                ...gridProps,
                item: true,
            },
        } : {
            InputWrapper: Box,
            props: {
                style: {
                    margin: "15px 0px",
                    ...boxProps,
                },
            },
        }
    ), [boxProps, gridProps, useGrid]);

    return (
        <ContainerWrapper {...containerWrapperProps}>
            {Children.map(children, (child) => (
                <InputWrapper {...inputWrapperProps}>
                    {child}
                </InputWrapper>
            ))}
        </ContainerWrapper>
    );
};

export default Grid;