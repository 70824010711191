import React, { useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import MuiGrid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import { useMediaQuery } from "../hooks/use-media-query";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { makeStyles } from "../hooks/make-styles-wrapper";
import Header from "../components/Header";
import { componentIndex, getRenderList } from "./inputs";
import clsx from "clsx";

const useHeaderStyles = makeStyles(
    (theme, overrides, merge) => ({
        container: merge(
            {
                width: "288px",
                height: "auto",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "left",

                [theme.breakpoints.up(370)]: {
                    width: "320px",
                },
                [theme.breakpoints.up(400)]: {
                    width: "350px",
                },
                [theme.breakpoints.up(500)]: {
                    width: "100%",
                },
            },
            overrides,
        ),
        title: {
            fontSize: "14px",
            fontWeight: 700,

            [theme.breakpoints.up(370)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up(500)]: {
                fontSize: "18px",
            },
        },
    }),
    "header.container",
);

const useGridItemStyles = makeStyles(
    (theme, overrides, merge) => ({
        root: merge(
            {},
            overrides,
        ),
    }),
    "inputsRenderer.gridItem",
);

const useWrapperStyles = makeStyles(
    (theme, overrides, merge) => ({
        root: merge(
            {},
            overrides,
        ),
    }),
    "inputsRenderer.inputWrapper",
);

const useGridWrapperStyles = makeStyles((theme) => ({
    container: {
        "& .MuiGrid-item .MuiTextField-root": {
            width: "100%",
        },
        "& .MuiGrid-item .MuiBox-root": {
            [theme.breakpoints.down(650)]: {
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
            },
        },
    },
}));

export const InputComponent = ({
    step,
    stepName,
    stepInput,
    transitionDelayForInput,

    context,
    formActions,
    actions,
}) => {

    const inputCount = step.inputs.length;

    const {
        key,
        type,
        prompt,
        styles, // inline styles from step input
        index, // index === undefined --> chat-bot instance
    } = stepInput;

    const {
        lead,
        getLeadField,
        updateLeadState,
        viewState,
        updateViewState,
    } = context;

    const {
        setValue,
        errors,
    } = formActions;

    const [fieldValue, setFieldValue] = lead.useField(key);
    const { disableInputTransitions } = useSiteMetadata();

    /**
     * Grabs the form error validation message associated with the input field. If the
     * error validation object has nested properties, it returns the error object of
     * the step input.
     * @param {string} name - the field name to query. Defaults to the
     *     step input name.
     * @return {string} the input validation error message or `undefined` otherwise.
    */
    const getFormError = useCallback((name) => {
        const fieldName = name || key;
        if (errors[fieldName] && !errors[fieldName].message) {
            return errors[fieldName];
        }
        return errors[fieldName]?.message;
    }, [errors, key]);

    /**
     * Updates the value of a field in the form.
     * @param {any} value - the form value to be updated.
     * @param {boolean} shouldValidate - determines whether
     *     the input should validate in the form. Defaults to `true`.
    */
    const updateFormField = useCallback((value, shouldValidate) => {
        const setValueConfig = {
            shouldValidate: shouldValidate ?? true,
        };
        setValue(key, value, setValueConfig);
    }, [setValue, key]);

    /**
     * Retrieves the value of a field in the lead state.
     * @param {string} name - the field name to query. Defaults to the
     *     step input name.
     * @return {string} the lead field value.
    */
    const getField = useCallback((name) => {
        const fieldName = name || key;
        return getLeadField(fieldName);
    }, [getLeadField, key]);

    /**
     * Updates the value of a field in the lead state.
     * @param {any} value - the lead field value to be updated.
     * @param {string} name - an optional param if a different input name
     *     is to be used when updating a field value. Default is the step
     *     input name.
    */
    const updateField = useCallback((value, name) => {
        if (name && name !== key) {
            lead.fields.set(name, value);
            return;
        }
        setFieldValue(value);
    }, [setFieldValue, key, lead]);

    /**
     * Updates the field value in the form state & lead state.
     * @param {any} value - the value to be updated.
    */
    const saveToAppState = useCallback((value) => {
        updateFormField(value);
        updateField(value);
    }, [updateField, updateFormField]);

    const common = {
        index,
        lead,
        name: key,
        styles: styles || {},
        error: getFormError(),
        inputCacheValue: fieldValue,
        // app state helpers
        updateFormField,
        getField,
        updateField,
        getFormError,
        saveToAppState,
        updateLeadState,
        viewState,
        updateViewState,
    };

    const meta = componentIndex[type];
    const Component = meta.component;
    const componentProps = meta.getProps(stepInput, formActions, actions);
    const isChatBot = index === undefined;

    const {
        Transition,
        props: transitionProps,
    } = useMemo(() => {
        if (disableInputTransitions || isChatBot) {
            return {
                Transition: React.Fragment,
                props: {},
            };
        }
        return {
            Transition: Fade,
            props: {
                in: true,
                style: { transitionDelay: transitionDelayForInput },
                timeout: 500,
            },
        };
    }, [disableInputTransitions, isChatBot, transitionDelayForInput]);

    if (meta.register) {
        formActions.register({
            name: key,
            type: "custom",
        });
    }

    return (
        <Transition {...transitionProps}>
            <Box>
                <Component
                    {...common}
                    {...componentProps}
                />
                {index !== inputCount - 1 && (
                    <Box mb={"space" in meta ? meta.space : 2} />
                )}
            </Box>
        </Transition>
    );
};

export const InputsRenderer = ({ renderList, ...props }) => {
    const {
        step,
        gridItemProps,
    } = props;

    const isGridItem = !!gridItemProps;

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(650), { noSsr: true });
    const renderListLength = renderList.length;
    const headerStyles = useHeaderStyles();
    const wrapperStyles = useWrapperStyles();
    const gridWrapperStyles = useGridWrapperStyles();
    const gridItemStyles = useGridItemStyles();

    return renderList.map((stepInput, i) => {
        const { InputWrapper, props: inputWrapperProps } = (
            isGridItem ? {
                InputWrapper: MuiGrid,
                props: {
                    xs: 12,
                    ...!isSmallScreen && gridItemProps,
                    item: true,
                    className: gridItemStyles.root,
                },
            } : {
                InputWrapper: Box,
                props: {
                    className: clsx(wrapperStyles.root),
                },
            }
        );

        const headerProps = {
            title: stepInput.prompt,
            boxStyles: headerStyles.container,
            titleStyles: headerStyles.title,
        };

        if (stepInput.group) {
            const {
                grid,
                inputs,
                prompt,
            } = stepInput;

            const { renderList } = getRenderList(
                step,
                inputs,
                renderListLength,
            );

            const { GroupWrapper, props: groupWrapperProps } = (
                grid ? {
                    GroupWrapper: MuiGrid,
                    props: {
                        key: "group_inputs",
                        ...grid.containerProps || {},
                        container: true,
                        className: gridWrapperStyles.container,
                    },
                } : {
                    GroupWrapper: Box,
                    props: {},
                }
            );

            return (
                <React.Fragment
                    key={`group-wrapper-${i}`}
                >
                    {prompt && <Header {...headerProps} title={prompt} />}
                    <GroupWrapper {...groupWrapperProps}>
                        <InputsRenderer
                            {...props}
                            renderList={renderList}
                            gridItemProps={grid.itemProps || {}}
                        />
                    </GroupWrapper>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment
                key={`input-wrapper-${stepInput.key}`}
            >
                {stepInput.prompt && !isGridItem && <Header {...headerProps} />}
                <InputWrapper
                    {...inputWrapperProps}
                >
                    <InputComponent
                        {...props}
                        stepInput={stepInput}
                    />
                </InputWrapper>
            </React.Fragment>
        );
    });
};