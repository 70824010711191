import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export const SuccessBlurb = (props) => {
    const {
        title,
        body,
    } = props;

    const imageData = useStaticQuery(query);
    const successImage = imageData.appSuccessImg.childImageSharp.fluid;

    return (
        <React.Fragment>
            <Box
                textAlign="center"
                my={5}
            >
                <Typography
                    component="h1"
                    variant="h1"
                    color="primary"
                    style={{ fontSize: "40px" }}
                >
                    {title}
                </Typography>
            </Box>
            <Box
                textAlign="center"
                mt={5}
                mb={3}
            >
                <Typography
                    component="p"
                    variant="body1"
                    style={{ fontSize: "16px" }}
                >
                    {body}
                </Typography>
            </Box>
            <Box
                display="flex"
                position="relative"
                justifyContent="center"
            >
                <Img
                    fluid={successImage}
                    loading="eager"
                    alt="application success image"
                    durationFadeIn={0}
                    style={{ width: "80%" }}
                />
            </Box>
            <Box my={4} />
        </React.Fragment>
    );
};

export default SuccessBlurb;

const query = graphql`
    {
        appSuccessImg: file(relativePath: { eq: "app-success.png" }){
            childImageSharp{
                fluid(quality: 90){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;