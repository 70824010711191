import React, { useState, useMemo } from "react";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const Select = (props) => {
    const {
        // common
        name,
        error,
        inputCacheValue,
        updateFormField,
        saveToAppState,
        // stepInput props
        label,
        multiple,
        options,
        // component props
        value,
        styles,
    } = props;

    const [selectValue, setSelectValue] = useState(() => {
        return (inputCacheValue)
            ? inputCacheValue
            : multiple ? [] : "";
    });

    const helperText = error
        ? props.helperText || error
        : null;

    const labelId = `${props.key}-label`;

    const inputLabel = React.useRef(null);
    const selectProps = {
        "data-cy": name,
    };
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        if (selectValue) {
            updateFormField(selectValue);
        }
    }, [selectValue, updateFormField]);

    const onChange = (e) => {
        const value = e.target.value;
        setSelectValue(value);
        saveToAppState(value);
    };

    const handleRenderValue = (val) => {
        let valToRender = multiple ? [] : "";
        options.forEach((opt) => {
            const { label, value } = opt;
            if (typeof val === "string" && val === value) {
                valToRender = label;
            } else {
                /* `val` is an array */
                if (val.includes(value)) {
                    valToRender.push(label);
                }
            }
        });
        return multiple ? valToRender.join(", ") : valToRender;
    };

    const renderSelectItems = useMemo(() => {
        return options.map((v, i) => {
            const Items = multiple
                ? (
                    <React.Fragment>
                        <Checkbox color="primary" checked={selectValue.indexOf(v.value) > -1} />
                        <ListItemText primary={v.label} />
                    </React.Fragment>
                )
                : v.label;
            return (
                <MenuItem
                    data-cy={v.value}
                    value={v.value}
                    key={i}
                >
                    {Items}
                </MenuItem>
            );
        });
    }, [multiple, options, selectValue]);

    return (
        <FormControl
            variant="outlined"
            fullWidth
            error={!!error}
        >
            <InputLabel
                id={labelId}
                ref={inputLabel}
            >
                {label}
            </InputLabel>
            <MuiSelect
                labelId={labelId}
                classes={props.classes}
                className={props.className}
                style={styles}
                ref={props.inputRef}
                name={props.key}
                value={value || selectValue}
                renderValue={handleRenderValue}
                onChange={props.onChange || onChange}
                multiple={multiple}
                options={options}
                labelWidth={labelWidth}
                SelectDisplayProps={selectProps}
            >
                {renderSelectItems}
            </MuiSelect>
            {!!error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

Select.propTypes = {

};

Select.defaultProps = {

};

export default Select;
