import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import Sentry from "@clcdev/gatsby-plugin-sentry";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { withStyles } from "@material-ui/core/styles";
import { country, countryName, regionCodes } from "@locales";
import TextInput from "@components/TextInput";
import ManualAddress from "./ManualAddress";
import AutocompleteReady from "../utils/autocomplete-ready";
import { isArraysEqual } from "../utils/is-arrays-equal";
import { makeStyles } from "../hooks/make-styles-wrapper";
import clsx from "clsx";

const useAddressStyles = makeStyles(
    (theme, overrides, merge) => ({
        container: merge(
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            overrides,
        ),
        link: {
            marginTop: "15px",
            width: "fit-content",
            cursor: "pointer",
        },
    }),
    "address.container",
);

const InfoIcon = withStyles((theme) => ({
    root: {
        fill: `${theme.palette.primary.main} !important`,
        cursor: "pointer",
        margin: "5px",
    },
}))(InfoOutlinedIcon);

// google place components we want to capture
const placeComponents = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    postal_code: "short_name",
};
// required address component keys
const requiredAddressComponents = Object.keys(placeComponents);
// map the properties we need for appData
const addressPropertyMapping = {
    locality: "city",
    administrative_area_level_1: "provinceCode",
    postal_code: "postalCode",
};
/** A list of provinces that are currently unserviceable. */
export const provinceBlacklist = ["QC"];

export const findProvince = (provinceCode) => {
    return regionCodes.find(({ label, value }) => value === provinceCode);
};

// clean up extra "pac-container pac-logo" divs that are added to the DOM
const cleanUpDom = () => {
    // Node list of all div.pac-container.pac-logo elements
    const pacList = document.querySelectorAll("div.pac-container, div.pac-logo");
    pacList.forEach((div) => div.remove());
};

const Address = (props) => {
    const {
        /* common props */
        name,
        styles,
        className,
        inputCacheValue,
        getField,
        updateField,
        updateFormField,
        updateLeadState,
        viewState,
        updateViewState,
        error, // form error
        /* component props */
        getValues,
        setError, // formActions.setError fn
        disableManualInputs,
    } = props;

    const inputId = "address";
    const componentStyles = useAddressStyles();
    const addressInputRef = useRef(null);
    const [manualLinkText, setManualLinkText] = useState("Enter your address manually");

    const contextManualInput = viewState.manualAddress;

    // DO NOT USE!  SSR COMPATIBILITY ONLY!
    // This switch controls which component renders, but that is the only thing it
    // should control, and it should only be set by `useLayoutEffect` below.
    // To change the value, change the context value above.
    // Any other conditions should use `contextManualInput`
    const [manualInput, setManualInput] = useState(false);

    useLayoutEffect(() => {
        setManualInput(contextManualInput);
    }, [contextManualInput]);

    // initialize address autocomplete
    useEffect(() => {
        const isProvinceBlacklisted = (provinceCode) => {
            return provinceBlacklist.includes(provinceCode);
        };

        if (contextManualInput) return;
        if (inputCacheValue) {
            const addrState = !isProvinceBlacklisted(getField("provinceCode"))
                ? {
                    address: inputCacheValue,
                    city: getField("city"),
                    provinceCode: getField("provinceCode"),
                    postalCode: getField("postalCode"),
                } : {};
            updateFormField(addrState);
        } else {
            updateFormField({}, false);
        }

        let autocomplete;
        AutocompleteReady.then(() => {
            // event handler
            const saveContent = async () => {
                const addressResult = {};
                let foundAddressComponents = [];
                // get the selected place details
                const selectedPlace = autocomplete.getPlace();
                // if partial or non-existent address is found, force new selection
                if (!selectedPlace.address_components) {
                    updateFormField(addressResult);
                    return;
                }
                // if selected place does not have all required components, force new selection
                selectedPlace.address_components.forEach((component) => {
                    if (requiredAddressComponents.includes(component.types[0]))
                        foundAddressComponents.push(component.types[0]);
                });
                if (!isArraysEqual(requiredAddressComponents, foundAddressComponents)) {
                    updateFormField(addressResult);
                    return;
                }
                // if all components exist & input value is valid
                let fullStreet = "";
                for (let component of selectedPlace.address_components) {
                    const addressComponentType = component.types[0];
                    const placeComponentVariant = placeComponents[addressComponentType];
                    const addressResultProperty = addressPropertyMapping[addressComponentType];
                    const addressComponentValue = component[placeComponentVariant];

                    if (addressComponentType === "street_number" || addressComponentType === "route") {
                        fullStreet += `${addressComponentValue} `;
                    } else {
                        addressResult[addressResultProperty] = addressComponentValue;
                    }
                }
                // if province is unserviceable, force a new selection
                if (isProvinceBlacklisted(addressResult.provinceCode)) {
                    const provinceObj = findProvince(addressResult.provinceCode);
                    const msgContent = provinceObj ? `${provinceObj.label} Residents.` : "your area.";
                    setError(name, {
                        type: "provinceCode",
                        message: `At this time, we are unable to service ${msgContent}`,
                    });
                    return;
                }
                addressResult["address"] = fullStreet.trim();
                updateLeadState(addressResult);
                updateFormField(addressResult);
            };
            try {
                const htmlInputElem = addressInputRef.current;
                const options = {
                    types: ["address"],
                    componentRestrictions: { country: country || "ca" },
                };
                autocomplete = new window.google.maps.places.Autocomplete(htmlInputElem, options);
                autocomplete.setFields(["address_component"]); // restrict the set of fields returned since each field costs money
                autocomplete.addListener("place_changed", saveContent);
            } catch (err) {
                Sentry.captureException(err);
            }
        }).then(() => {
            cleanUpDom();
        });

        return () => {
            if (!autocomplete) return;
            autocomplete.unbindAll();
        };
    }, [contextManualInput, getField, inputCacheValue, updateFormField, updateLeadState, setError, name]);

    const helperText = useMemo(() => {
        if (!error) return null;
        if (typeof error === "string") return error;
        return props.helperText || "Please enter a valid address";
    }, [error, props.helperText]);

    const tooltipContent = `
        This input will auto-complete your address as you type. You must make a selection from the results.
    `;
    const tooltipProps = {
        content: tooltipContent,
        component: <InfoIcon />,
    };

    const onManualClick = () => updateViewState("manualAddress", !contextManualInput);

    const handleOnChange = () => {
        const linkText = (AutocompleteReady.isReady)
            ? "I can't find my address. Enter manually"
            : "Don't see any results? Enter manually";

        setManualLinkText(linkText);
        if ((getValues(name) || {}).address) {
            updateFormField({}, false);
        }
    };

    const getFullAddress = useMemo(() => {
        const address = getField("address");
        const city = getField("city");
        const province = getField("provinceCode");
        const postalCode = getField("postalCode");
        const isAllValid =
            address &&
            city &&
            province &&
            postalCode &&
            !contextManualInput;

        if (isAllValid) {
            return [
                address,
                city,
                province,
                postalCode,
                countryName,
            ].join(", ");
        }
    }, [contextManualInput, getField]);

    return (
        <Box className={clsx(componentStyles.container)}>
            {!manualInput ? (
                <TextInput
                    id={inputId}
                    inputRef={addressInputRef}
                    name={name}
                    styles={styles}
                    className={className}
                    label={props.label}
                    error={!!error}
                    helperText={helperText}
                    onChange={handleOnChange}
                    tooltip={tooltipProps}
                    inputCacheValue={getFullAddress}
                    updateField={updateField}
                />
            ) : (
                <ManualAddress {...props} />
            )}
            <Box mb="25px" />
            <Link
                data-cy="manual"
                variant="body2"
                className={componentStyles.link}
                style={{ display: disableManualInputs ? "none" : "flex" }}
                onClick={onManualClick}
            >
                {(!manualInput) ? manualLinkText : "Back to Autocomplete Address"}
            </Link>
        </Box>
    );
};

export default Address;