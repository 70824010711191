import React, { useState, useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Chip from "./Chip";

const ChipGroup = (props) => {
    const [selected, setSelected] = useState(null);
    // destructure props
    const {
        // common
        name,
        saveToAppState,
        // component props
        options,
        touched,
        autoContinue,
        goToNextStep,
    } = props;

    const onBlur = useCallback((i) => (e) => {
        if (i === selected) e.target.focus();
    }, [selected]);

    const inputs = useMemo(() => options.map((option, i) => {
        const { label, value } = option;
        const onClick = (e) => {
            setSelected(i);
            saveToAppState(value);
            if (autoContinue) goToNextStep();
        };
        return (
            <div key={i}>
                <Chip
                    name={value}
                    label={label}
                    onBlur={onBlur(i)}
                    focused={selected === i}
                    onClick={onClick}
                />
                {i !== options.length - 1 && <Box mb={2} />}
            </div>
        );
    }), [autoContinue, goToNextStep, onBlur, options, saveToAppState, selected]);

    const error = useMemo(() => {
        if (props.error)
            return (
                <Box
                    component="span"
                    position="absolute"
                    left={0}
                    textAlign="center"
                    width="100%"
                    top="100%"
                    mt={0.5}
                    color="error.main"
                >
                    {props.error}
                </Box>
            );
    }, [props.error]);

    return (
        <Box
            data-cy={name}
            position="relative"
            width="220px"
        >
            {inputs}
            {error}
        </Box>
    );
};

export default ChipGroup;
