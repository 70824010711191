export const successCTAs = {
    header: "Let us help you with more than your car loan!",
    subheader: "Insurance, Credit Score, Loan Completion, and more…",
    ctas: [
        {
            name: "SONNET",
            title: "Car Insurance Online",
            description: "Quote and buy auto insurance online instantly. You can protect your car in just 5 minutes with Sonnet's customizable plans.",
            link: "https://www.carloanscanada.com/insurance",
            buttonCta: "Apply for Insurance",
        },
        {
            name: "BORROWELL",
            title: "Free Credit Score Checks",
            description: "Your credit score determines your eligibility and risk rate to lenders or anyone looking to give you credit.",
            link: "https://www.carloanscanada.com/credit-score",
            buttonCta: "Check your Credit Score",
        },
        {
            name: "LOANS CANADA",
            title: "Complete your Loan Online",
            description: "Find a competitive loan offer that best suits your needs!",
            link: "https://loanscanada.ca/app/loan?affiliate_id=376",
            buttonCta: "Complete your Loan",
        },
    ],
};