import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import ContinueButton from "@components/ContinueButton";

const useStyles = makeStyles((theme) => ({
    ctaBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px",
        padding: "40px 15px",
        [theme.breakpoints.up(600)]: {
            flexDirection: "row",
        },
    },
    ctaBoxMobile: {
        [theme.breakpoints.up(300)]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.up(600)]: {
            flexDirection: "row",
        },
    },
    imgBox: {
        width: "45%",
        marginBottom: "40px",
        [theme.breakpoints.up(300)]: {
            marginLeft: "0px",
        },
        [theme.breakpoints.up(450)]: {
            width: "35%",
        },
        [theme.breakpoints.up(600)]: {
            width: "40%",
            marginTop: 0,
            marginLeft: "32px",
            marginBottom: "20px",
        },
    },
    imgBoxLeft: {
        [theme.breakpoints.up(600)]: {
            marginLeft: "0px",
        },
    },
    contentBox: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    textBold: {
        fontWeight: 800,
    },
    textOrientation: {
        textAlign: "left",
        [theme.breakpoints.up(600)]: {
            textAlign: "right",
            marginLeft: "40px",
        },
    },
    descriptionBox: {
        [theme.breakpoints.up(600)]: {
            flexDirection: "row",
        },
    },
    ctaButtonRight: {
        float: "none",
        [theme.breakpoints.up(600)]: {
            float: "right",
        },
    },
}));

const SuccessCtaContainer = (props) => {
    const ctaLink = props.link;
    const imagePosition = props.imagePosition;
    const styles = useStyles();

    // handler for CTA button
    const handleCTA = (e) => {
        window.location.href = ctaLink;
    };
    // ImageContainer function component
    const ImageContainer = () => (
        <Box
            className={imagePosition === "left" ? `${styles.imgBox} ${styles.imgBoxLeft}` : styles.imgBox}
        >
            <Img
                fluid={props.image}
                loading="lazy"
                alt="cta-image"
                durationFadeIn={100}
            />
        </Box>
    );
    // ContentContainer function component
    const ContentContainer = () => (
        <Box
            className={styles.contentBox}
        >
            <Typography
                variant="body2"
                component="h1"
                color="textPrimary"
                className={imagePosition === "left" ? `${styles.textOrientation} ${styles.textBold}` : styles.textBold}
                fontSize="12px"
                letterSpacing="-0.03px"
            >
                {props.name}
            </Typography>
            <Box
                mt={2}
            >
                <Typography
                    variant="h6"
                    component="h1"
                    fontSize="18px"
                    className={imagePosition === "left" ? `${styles.textOrientation} ${styles.textBold}` : styles.textBold}
                    letterSpacing ="-0.05px"
                >
                    {props.title}
                </Typography>
            </Box>
            <Box
                className={styles.descriptionBox}
                mt={2}
            >
                <Typography
                    variant="body2"
                    component="h1"
                    className={imagePosition === "left" ? styles.textOrientation : ""}
                >
                    {props.description}
                </Typography>
            </Box>
            <Box
                className={imagePosition === "left" ? styles.ctaButtonRight: ""}
                mt={6}
                mb={imagePosition === "left" ? 0 : 2}
            >
                <ContinueButton
                    text="LEARN MORE"
                    onClick={handleCTA}
                />
            </Box>
        </Box>
    );
    // populate cta container based on image position
    const populateContainer = (position) => (
        position === "left" ?
            (<>
                <ImageContainer />
                <ContentContainer />
            </>) :
            (<>
                <ContentContainer />
                <ImageContainer />
            </>)
    );

    return (
        <Box
            className={imagePosition === "left" ? styles.ctaBox : `${styles.ctaBox} ${styles.ctaBoxMobile}`}
            bgcolor={imagePosition === "left" ? "#f3f4f6" : "transparent"}
        >
            {populateContainer(imagePosition)}
        </Box>
    );
};

export default SuccessCtaContainer;