import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import SuccessCtaContainer from "@components/SuccessCtaContainer";
import { successCTAs } from "../data/success-ctas";
import { graphql, useStaticQuery } from "gatsby";
import { config } from "config";

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        margin: 0,
        padding: 0,
    },
    header: {
        fontSize: "26px",
        fontWeight: "800",
        letterSpacing: "-0.06px",
        lineHeight: "36px",
        padding: "0px 5px",
    },
    subheader: {
        fontSize: "16px",
        padding: "0px 5px",
        [theme.breakpoints.up(600)]: {
            fontSize: "18px",
        },
    },
}));

const SuccessCtaSection = (props) => {
    const {
        compact,
    } = props;

    const styles = useStyles();
    const imageData = useStaticQuery(imageQueries);
    const header = successCTAs.header;
    const subheader = successCTAs.subheader;
    const ctaList = successCTAs.ctas;

    // populate section with SuccessCtaContainers
    const getSuccessCtaContainers = () => ctaList.map((cta, i) => {
        const getCtaContainerProps = () => {
            if (compact) {
                return {
                    name: cta.name,
                    title: cta.title,
                    description: cta.description,
                    link: cta.link,
                    buttonText: cta.buttonCta,
                };
            }
            return {
                name: cta.name,
                title: cta.title,
                description: cta.description,
                link: cta.link,
                image: imageData[`ctaImage${i}`].childImageSharp.fluid,
                imagePosition: (i % 2 === 0) ? "right" : "left",
            };
        };
        const ctaContainerProps = getCtaContainerProps();
        return (
            <SuccessCtaContainer
                key={i}
                {...ctaContainerProps}
            />
        );
    });

    return (
        <Container className={styles.sectionContainer} >
            {!compact &&
                <React.Fragment>
                    <Box
                        mt={10}
                        mb={4}
                    >
                        <Typography
                            variant="h1"
                            component="h1"
                            align="left"
                            className={styles.header}
                        >
                            {header}
                        </Typography>
                    </Box>
                    <Box
                        my={4}
                    >
                        <Typography
                            variant="h6"
                            component="h1"
                            align="left"
                            className={styles.subheader}
                        >
                            {subheader}
                        </Typography>
                    </Box>
                </React.Fragment>
            }
            {!config.hideSuccessCtas && getSuccessCtaContainers()}
        </Container>
    );
};

export default SuccessCtaSection;

export const imageQueries = graphql`
    {
        ctaImage0: file(relativePath: { eq: "CLC_sonnet_car+.png" }){
            childImageSharp{
                fluid(maxWidth: 400, quality: 90){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        ctaImage1: file(relativePath: { eq: "group-5.png" }){
            childImageSharp{
                fluid(maxWidth: 400, quality: 90){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        ctaImage2: file(relativePath: { eq: "group-2.png" }){
            childImageSharp{
                fluid(maxWidth: 400, quality: 90){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;