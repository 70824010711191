import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    p: {
        lineHeight: "16px",
        color: theme.palette.secondary.light,
    },
    headerStyles: {
        width: "331px",
        height: "110px",
        display: "flex",
        textAlign: "center",
        flexDirection: "column",

        [theme.breakpoints.down(350)]: {
            width: "auto",
        },
    },
}));

const Header = (props) => {
    const {
        title,
        description,
        boxStyles,
        titleStyles,
        descriptionStyles,
    } = props;

    const styles = useStyles();

    return (
        <Box
            className={boxStyles || styles.headerStyles}
            mx="auto"
        >
            <Typography
                component="h1"
                variant="h5"
                color="secondary"
                className={titleStyles}
            >
                {title}
            </Typography>
            <Box my={1} />
            <Typography
                component="p"
                variant="body2"
                className={descriptionStyles || styles.p}
            >
                {description}
            </Typography>
        </Box>
    );
};

export default Header;
