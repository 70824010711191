import { makeStyles as makeMuiStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import get from "lodash/get";

export const makeStyles = (styles, overridePath) => (
    makeMuiStyles((theme) => {
        const overrides = overridePath
            ? get(theme, `overrides.App.${overridePath}`, {})
            : {};

        return (
            typeof styles === "function"
                ? styles(theme, overrides, merge)
                : styles
        ) || {};
    })
);