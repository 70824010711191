import React, { useState, useRef, createRef, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { config } from "config";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { formatPhoneNumber } from "../utils/format";
import {
    sendVerificationCode,
    checkVerificationCode,
} from "../api/twilio-verify";

/* styles */
const useInputCellStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-evenly",
        color: "#525461",
        marginTop: "20px",
        marginBottom: "24px",

        "&:focus": {
            outline: "none",
        },
    },
}));

const BaseCodeVerification = (props) => {
    const {
        // common
        index,
        getField,
        updateField,
        // component props
        inputType = "number",
        numberOfCells = 4,
        cellWidth = 50,
        cellHeight = 45,
        autoFocus = true,
        goToNextStep,
        onComplete,
        classes,
    } = props;

    const twilioVerifyMethods = config.twilioVerifyMethods;
    const { twilioVerifyRequired } = useSiteMetadata();

    const inputCellStyles = useInputCellStyles();

    const autoFocusIdx = useRef(0);
    const [cellValues, setCellValues] = useState(() => {
        return {
            values: new Array(numberOfCells).fill(""),
            autoFocusIdx,
        };
    });
    const [disableButton, setDisableButton] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isError, setIsError] = useState(false);
    const message = useRef("");
    const verifyAttempts = useRef(0);
    const channel = useRef(
        getField("verifyOptions") ||
            (twilioVerifyMethods.length === 1 && twilioVerifyMethods[0]),
    );
    const contactTo = useRef(
        channel.current === "email"
            ? getField("email")
            : `+1${formatPhoneNumber(getField("phoneNumber"))}`,
    );

    const [inputCellRefs, setInputCellRefs] = useState(() => {
        return Array(numberOfCells)
            .fill()
            .map((_, i) => createRef());
    });

    useEffect(() => {
        async function onInitialLoad() {
            if (channel.current && contactTo.current) {
                if (!getField("twilioVerified")) {
                    updateField("twilioVerified", false);
                }
                console.log("sending out verification code...");
                const status = await sendVerificationCode(
                    contactTo.current,
                    channel.current,
                );
            } else {
                console.error(
                    "Could not send verification code: missing either 'channel' or 'contact' value",
                );
            }
        }
        onInitialLoad();
    }, [getField, updateField]);

    const clearInput = () => {
        setCellValues({ values: new Array(numberOfCells).fill("") });
        inputCellRefs[0].current.focus();
    };

    const onInputChange = (values) => {
        setShowMessage(false);
        setDisableButton(false);
    };

    const onInputComplete = async (values) => {
        verifyAttempts.current += 1;
        const status = await checkVerificationCode(contactTo.current, values);
        if (status === "approved") {
            updateField(true, "twilioVerified");
            message.current = "You have been verified!";
            setIsError(false);
            setShowMessage(true);
            await onComplete(index);
        } else {
            if (verifyAttempts.current < 6) {
                message.current = "Wrong Code! Please Try Again.";
                setIsError(true);
                setShowMessage(true);
                setTimeout(() => {
                    clearInput();
                }, 1000);
            } else {
                /* status: undefined */
                message.current =
                    "Too Many Attempts. Please contact us for further support.";
                setIsError(true);
                setShowMessage(true);
            }
        }
    };

    const onResendCode = async (e) => {
        setShowMessage(false);
        setDisableButton(true);
        e.preventDefault();
        const status = await sendVerificationCode(
            contactTo.current,
            channel.current,
        );
        if (!status) {
            message.current = "Verification cannot be sent at this time.";
            setIsError(true);
        } else {
            message.current = "Another Code has been sent!";
            setIsError(false);
        }
        setShowMessage(true);
    };

    const handleChange = (valuesArr = cellValues.values) => {
        const values = valuesArr.join(""); // string
        onInputChange(values);
        values.length >= numberOfCells && onInputComplete(values);
    };

    const onChange = (e) => {
        let { values } = cellValues;
        const currCellIdx = parseInt(e.target.dataset.id);
        let val = e.target.value;
        let nextRef = null;

        if (inputType === "number") val = val.replace(/[^\d]/gi, "");
        if (val === "") return;

        values = Object.assign([], values);
        if (val.length > 1) {
            let nextCellIdx = val.length + (currCellIdx - 1);
            if (nextCellIdx >= numberOfCells) nextCellIdx = numberOfCells - 1;

            nextRef = inputCellRefs[nextCellIdx];
            val.split("").forEach((char, i) => {
                const cursor = currCellIdx + i;
                if (cursor < numberOfCells) values[cursor] = char;
            });
            setCellValues({ values });
        } else {
            nextRef = inputCellRefs[currCellIdx + 1];
            values[currCellIdx] = val;
            setCellValues({ values });
        }

        if (nextRef) {
            nextRef.current.focus();
            nextRef.current.select();
        }
        handleChange(values);
    };

    const doKeyDownAction = (e, indices) => {
        const { keyCode } = e;
        const { currCellIdx, prevIdx, prevRef, nextRef } = indices;
        switch (keyCode) {
            case 8 /* backspace */:
                e.preventDefault();
                const values = [...cellValues.values];
                if (cellValues.values[currCellIdx]) {
                    values[currCellIdx] = "";
                    setCellValues({ values });
                    handleChange(values);
                } else if (prevRef) {
                    values[prevIdx] = "";
                    prevRef.current.focus();
                    setCellValues({ values });
                    handleChange(values);
                }
                break;
            case 37 /* left */:
                e.preventDefault();
                if (prevRef) prevRef.current.focus();
                break;
            case 39 /* right */:
                e.preventDefault();
                if (nextRef) nextRef.current.focus();
                break;
            case 38 /* up */:
                e.preventDefault();
                break;
            case 40 /* down */:
                e.preventDefault();
                break;
            default:
                break;
        }
    };

    const onKeyDown = (e) => {
        const currCellIdx = parseInt(e.target.dataset.id);
        const prevIdx = currCellIdx - 1;
        const nextIdx = currCellIdx + 1;
        const prevRef = inputCellRefs[prevIdx];
        const nextRef = inputCellRefs[nextIdx];

        const indices = { currCellIdx, prevIdx, nextIdx, prevRef, nextRef };
        doKeyDownAction(e, indices);
    };

    const onFocus = (e) => {
        e.target.select(e);
    };

    return (
        <Box classes={classes}>
            <Box classes={inputCellStyles}>
                {cellValues.values.map((val, i) => (
                    <input
                        key={`verification-cell-input-${i}`}
                        type={inputType === "number" ? "tel" : inputType}
                        pattern={inputType === "number" ? "[0-9]*" : null}
                        autoFocus={autoFocus && i === autoFocusIdx.current}
                        style={{
                            width: cellWidth,
                            height: cellHeight,
                            textAlign: "center",
                            fontSize: "22px",
                            fontFamily: "Poppins",
                            borderRadius: "4px",
                        }}
                        data-id={i}
                        value={val}
                        ref={inputCellRefs[i]}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                    />
                ))}
            </Box>
            <Box
                style={{
                    visibility: showMessage ? "visible" : "hidden",
                    textAlign: "center",
                    marginBottom: "40px",
                    color: isError ? "red" : "green",
                }}
            >
                {message.current}
            </Box>
            <Button
                onClick={onResendCode}
                disabled={disableButton}
                style={{ marginBottom: "6px" }}
            >
                Resend Code
            </Button>
            {!twilioVerifyRequired && (
                <Button onClick={goToNextStep}>Skip</Button>
            )}
        </Box>
    );
};

export default BaseCodeVerification;
