import React from "react";
import BasePhoneNumber from "../BasePhoneNumber";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    phoneWidth: {
        width: "300px",
        marginTop: "75px",
        marginBottom: "30px",
    },
});

const PhoneNumber = (props) => {
    const styles = useStyles();
    return (
        <BasePhoneNumber
            className={styles.phoneWidth}
            {...props}
        />
    );
};

export default PhoneNumber;
