import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import NoSsr from "@material-ui/core/NoSsr";
import MuiPhoneNumber from "material-ui-phone-number";
import clsx from "clsx";
import { useMediaQuery } from "../hooks/use-media-query";
import { makeStyles } from "../hooks/make-styles-wrapper";

const useLabelStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: theme.typography.body1.fontWeight,
    },
}));

const useFormHelperStyles = makeStyles((theme) => ({
    root: {
        fontSize: "12px",
        textAlign: "center",
        fontWeight: "500",
        position: "absolute",
        top: "100%",
        fontFamily: theme.typography.h5.fontFamily,
    },
}));

const useInputStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.h5.fontFamily,
        fontWeight: theme.typography.h5.fontWeight,
        fontSize: "15px",
        letterSpacing: "0.5px",
    },
}));

const useBoxStyles = makeStyles(
    (theme, overrides, merge) => ({
        container: merge(
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            overrides,
        ),
    }),
    "phone.container",
);

const BasePhoneNumber = (props) => {
    const {
        name,
        styles,
        error,
        helperText,
        className,
        label,
        onKeyUp,
        hasAutoFocus,
        inputCacheValue,
        updateFormField,
        updateField,
    } = props;

    const disableNumber = name.slice(0, 5) + "Search" + name.slice(5);
    const labelStyles = useLabelStyles();
    const labelProps = {
        classes: labelStyles,
    };
    const formHelperStyles = useFormHelperStyles();
    const formHelperTextProps = {
        classes: formHelperStyles,
    };
    const inputStyles = useInputStyles();
    const inputContainerProps = {
        "data-cy": `${name}_container`,
        classes: inputStyles,
    };
    const inputProps = {
        "data-cy": name,
    };

    const boxStyles = useBoxStyles();

    const isDesktopBreakpoint = useMediaQuery((theme) => theme.breakpoints.up(1024), { noSsr: true });
    const shouldAutoFocus = hasAutoFocus && isDesktopBreakpoint;

    const helperTextValue = error
        ? helperText || error
        : null;

    useEffect(() => {
        // hydrate form state if input exists in cache
        if (inputCacheValue) {
            updateFormField(inputCacheValue, false);
        }
    }, [inputCacheValue, updateFormField]);

    const onChange = (v) => {
        updateFormField(v, false);
        updateField(v);
    };

    const onBlur = (e) => updateFormField(e.target.value);

    return (
        <NoSsr fallback={<Box width={300} height={56} />}>
            <Box
                className={clsx(boxStyles.container)}
            >
                <MuiPhoneNumber
                    onBlur={onBlur}
                    onChange={onChange}
                    helperText={helperTextValue}
                    className={className}
                    onlyCountries={["ca"]}
                    defaultCountry="ca"
                    name={disableNumber}
                    label={label}
                    value={inputCacheValue || undefined}
                    color="secondary"
                    error={!!error}
                    variant="outlined"
                    FormHelperTextProps={formHelperTextProps}
                    InputLabelProps={labelProps}
                    InputProps={inputContainerProps}
                    inputProps={inputProps}
                    disableDropdown="false"
                    onKeyUp={onKeyUp}
                    autoFocus={shouldAutoFocus}
                />
            </Box>
        </NoSsr>
    );
};

export default BasePhoneNumber;
