import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const SubmitBlurbContainer = withStyles((theme) => ({
    root: {
        height: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down(350)]: {
            height: "140px",
        },
        [theme.breakpoints.between(350, 960)]: {
            height: "180px",
        },
    },
}))(Box);

export const SubmitBlurb = (props) => {
    const {
        // common props
        name,
        // component props
        body1,
    } = props;

    return (
        <SubmitBlurbContainer>
            <Box textAlign="center">
                <Typography
                    variant="body2"
                    component="p"
                >
                    {body1}
                </Typography>
            </Box>
        </SubmitBlurbContainer>
    );
};

export default SubmitBlurb;